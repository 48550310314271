import React from "react";
import "./main.scss";

const Osnovna = () => {
  return (
    <div className="mainwrapper">
      <img src="./background.png" alt="asasa" className="backgr" />
    </div>
  );
};

export default Osnovna;
