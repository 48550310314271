import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import "./navbar.scss";

function NavBar() {
  // adding the states
  const [isActive, setIsActive] = useState(false);

  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (window.screen.width > 780) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("navbar").style.top = "0px";
      } else {
        document.getElementById("navbar").style.top = "75px";
      }
    }
  }
  return (
    <div className="App">
      <header className="App-header" id="navbar">
        <nav className="navbarr">
          {/* logo */}
          <a href="/final/" className="navLink">
            <img src="./full-logo-gold.png" alt="full_logo" />
          </a>

          <ul className={isActive ? "navMenu active" : "navMenu"}>
            <li onClick={removeActive}>
              <a href="/" className="navLink">
                Головна
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#Featured" className="navLink">
                  Популярні
                </HashLink>
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#About" className="locallink">
                  Про нас
                </HashLink>
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#Warranty" className="locallink">
                  Оплата
                </HashLink>
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#Comments" className="locallink">
                  Відгуки
                </HashLink>
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#Contact" className="locallink">
                  Менеджери
                </HashLink>
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#Blog" className="locallink">
                  Блог
                </HashLink>
              </a>
            </li>
            <li onClick={removeActive}>
              <a href="/final">
                <HashLink smooth to="/#Footer" className="locallink">
                  Детальніше
                </HashLink>
              </a>
            </li>
          </ul>

          <div
            className={isActive ? "hamburger active" : "hamburger"}
            onClick={toggleActiveClass}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default NavBar;
