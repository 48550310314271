import "./header.scss";
const Header = () => {
  return (
    <div className="headerwrapper">
      <div className="links">
        <div className="link">
          <img src="./phone.png" alt="" className="icons" />
          <span>
            <a href="tel:+38(099)9856515 ">+38 (099) 985 6515</a>
          </span>
        </div>
        <div className="link">
          <img src="./mail.png" alt="" className="icons" />
          <span>
            <a href="mailto:westautogrp@gmail.com">westautogrp@gmail.com</a>
          </span>
        </div>
        <div className="link">
          <img src="./time.png" alt="" className="icons" />
          <span>Пн-Пт 9:00-18:30</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
