import "./Calculator.scss";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Calculator = () => {
  return (
    <div className="calculatorWrapper">
      <div className="calculators">
        <div className="calculator">
          <span className="name">ПРИВЕЗЕНИХ АВТО</span>
          <CountUp end={600} redraw={true} duration={10}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
        </div>
        <div className="calculator">
          {" "}
          <span className="name">ЩАСЛИВІ КЛІЄНТИ</span>
          <CountUp end={350} redraw={true} duration={10}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>{" "}
        </div>
        <div className="calculator">
          {" "}
          <span className="name">РОКІВ ДОСВІДУ</span>
          <div className="">
            <CountUp end={5} redraw={true} duration={10}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>{" "}
            +
          </div>
        </div>
        <div className="calculator">
          {" "}
          <span className="name">СПЕЦІАЛІСТИ</span>
          <div className="">
            {" "}
            <CountUp end={10} redraw={true} duration={10}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
