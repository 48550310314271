// Допустимо, ваша сторінка має назву LatestArticles.js
import React from "react";
import Article from "../Article/Article";
import { Swiper, SwiperSlide } from "swiper/react";
import "./LatestArticles.scss";
const LatestArticles = () => {
  const articles = [
    {
      image: "./photos/rollover.png",
      author: "Назар",
      date: "24 Січня",
      title: "Типи пошкоджень авто на аукціонах та їх розшифрування",
      description:
        "Розшифрування пошкоджень на аукціонах буде для вас підказкою в пошуках відповідного авто. ",
      id: 1,
    },
    {
      image: "./photos/vin.png",
      author: "Артем",
      date: "19 Січня",
      title: "Як перевірити авто зі США перед покупкою",
      description:
        "Якщо ви не перевірите авто із США за VIN-кодом то вас чекають сюрпризи чи навіть серйозні проблеми під час розмитнення.  ",
      id: 2,
    },
    {
      image: "./article3_1.jpg",
      author: "Дмитро ",
      date: "23 Лютого",
      title: "Чи варто купувати биті авто зі США?",
      description:
        "Якщо ви не перевірите авто із США за VIN-кодом то вас чекають сюрпризи чи навіть серйозні проблеми під час розмитнення. ",
      id: 3,
    },
  ];

  return (
    <div className="articleswrapper">
      <div className="articlesLeft">
        <h2>Останні Статті</h2>
      </div>
      <div className="articlesRight">
        <Swiper
          slidesPerView={3}
          direction={
            window.screen.width < 780 ||
            (window.screen.width > 781) & (window.screen.width < 980)
              ? "vertical"
              : "horizontal"
          }
          className="mySwiper"
        >
          <div className="article">
            {articles.map((article, index) => (
              <SwiperSlide>
                <a href={`#/article/${article.id}`} className="articleLinks">
                  <Article key={index} {...article} />
                </a>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default LatestArticles;
