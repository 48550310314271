// Article.js
import React from "react";
import "./Article.scss";

const Article = ({ image, author, date, title, description }) => {
  return (
    <div className="article-container">
      <div className="image-container">
        <img src={image} alt={`Photo by ${author}`} className="article-image" />
      </div>
      <div className="article-details">
        <span className="article-date">{date}</span>
        <h3 className="article-title">{title}</h3>
        <p className="article-description">{description}</p>
        <p className="article-author">Автор: {author}</p>
      </div>
    </div>
  );
};

export default Article;
