// Article.js
import React from "react";
import "./Article1.scss";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";

const Article1 = ({ image, author, date, title, description }) => {
  return (
    <>
      <Header />
      <Navbar />
      <div className="article1wrap">
        <h2>Типи пошкоджень авто на аукціонах та їх розшифрування</h2>
        <p className="articletext">
          Розшифрування пошкоджень на аукціонах буде для вас підказкою в пошуках
          відповідного авто. Розуміючи всі позначення, ви швидко визначите, чи
          цей варіант прийнятний, а також уникнете ризику.
        </p>
        <h3>Розшифрування пошкоджень на аукціонах</h3>
        <ul>
          <li>All over (Всюди)</li>
          <li>Side (сторона)</li>
          <li>Top/Roof (Верх/Дах)</li>
          <li>Front End (Передня частина)</li>
          <li>Rear End (Задня частина)</li>
          <li>Normal Wear (Натуральне зношення)</li>
          <li>Vandalism (Вандалізм)</li>
          <li>Water/Flood (Вода/Залиті/Утопленики)</li>
          <li>Hail (Град)</li>
          <li>Burn (Пожежа)</li>
          <li>Biohazard/Chemical (Біологічна/хімічна небезпека)</li>
          <li>Mechanical (Механічні)</li>
          <li>Minor dents/Scratches (Невеликі вмʼятини/подряпини)</li>
          <li>Partial/Incomplete Repair (Частковий або незакінчений ремонт)</li>
          <li>Rejected Repair (Відхилений ремонт)</li>
          <li>Rollover (Переворот)</li>
          <li>Stripped (Розкрадений)</li>
          <li>Undercarriage (Проблеми з підвіскою, днищем)</li>
          <li>Recovered Thefts (Після викрадення)</li>
          <li>Missing/Altered VIN (Немає VIN, знищений, спиляний)</li>
          <li>VP-Replaced VIN (Замінено VIN-код)</li>
          <li>Donation (Пожертвування)</li>
          <li>Collision (Після ДТП)</li>
        </ul>
        <h3>All over (Всюди)</h3>
        <p>
          Стосується транспортних засобів, які мають пошкодження навколо всього
          периметра. В аукціонних формах це значення використовується, коли
          виявлено три або більше дефекти, наприклад:
          <ul>
            <li>Side (удар в бік);</li>
            <li>Front (в лоб);</li>
            <li> Rear End (ззаду);</li>
            <li> Roof (дах).</li>
          </ul>
        </p>
        <img src="./photos/rollover.png" alt="" />
        <p className="photoalt">Авто з пошкодженням All over</p>
        <h3>SIDE (СТОРОНА)</h3>
        <p>
          Пошкодження з однієї або одразу з двох сторін автівки. Бувають різної
          складності.{" "}
        </p>
        <p>
          Затратними вважаються пошкодження:
          <ul>
            <li> розбитого скла;</li>
            <li> дуже зім’ятим металом;</li>
            <li> двох або більше відділів.</li>
          </ul>
        </p>
        <img src="./photos/side.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Side</p>
        <h3>TOP/ROOF (ВЕРХ/ДАХ)</h3>
        <p>
          Дах чи верхня частина автомобіля повністю або частково пошкоджені. Це
          відбувається через:
        </p>
        <ul>
          <li> падіння на машину важкого предмета;</li>
          <li>
            {" "}
            зачеплення транспорту з вантажівкою, спецобладнанням або будівельною
            конструкцією;
          </li>
          <li> удар під час аварії чи нещасного випадку.</li>
        </ul>
        <img src="./photos/roof.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Top/Roof</p>
        <h3>FRONT END (ПЕРЕДНЯ ЧАСТИНА)</h3>
        <p>
          У таких лотах зазвичай пошкоджена передня частина з причини лобового
          зіткнення з іншим автомобілем, будівлею чи огорожею. Пошкодження авто
          front end зустрічаються на аукціонах найчастіше.
        </p>
        <p>
          У нас в пріоритеті будуть машини з розбитими:
          <ul>
            <li> фарами;</li>
            <li> опорними чашами;</li>
            <li> бампером;</li>
            <li> лонжероном.</li>
          </ul>
        </p>
        <img src="./photos/front.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Front End</p>
        <h3>REAR END (ЗАДНЯ ЧАСТИНА)</h3>
        <p>
          Заднє зіткнення не поступається частотою випадків лобовому, тому цих
          машин також багато на аукціонах. Характер пошкоджень, отриманих таким
          чином, залежить від особливостей аварії. Зазвичай подібне відбувається
          через:
          <ul>
            <li> неправильне паркування;</li>
            <li> порушення дистанції;</li>
            <li> різке гальмування.</li>
          </ul>
        </p>
        <img src="./photos/rear.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Rear End</p>
        <h3>NORMAL WEAR (НАТУРАЛЬНЕ ЗНОШЕННЯ)</h3>
        <p>
          Для транспортних засобів з цим позначенням характерні пошкодження,
          спричинені натуральним зносом у процесі експлуатації. Зауважте, такі
          лоти завжди йдуть з дуже великим пробігом, але при цьому мають
          незначні пошкодження кузова чи салону:
          <ul>
            <li> сліди іржі;</li>
            <li> вицвіті ділянки фарби;</li>
            <li> мікропошкодження, отримані від удару камінням;</li>
            <li> тьмяна оптика;</li>
            <li>
              затерті панелі, внутрішня оббивка, кермо, важіль коробки передач
              тощо.
            </li>
          </ul>
        </p>
        <img src="./photos/normal.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Normal Wear</p>
        <h3>VANDALISM (ВАНДАЛІЗМ)</h3>
        <p>
          Лоти з цієї групи пошкоджень авто зі США повинні вас зацікавити. А все
          тому, що шкода, отримана внаслідок акту вандалізму, може бути не такою
          критичною. За класикою жанру, хулігани:
          <ul>
            <li> вирізають сидіння автомобілів;</li>
            <li> фарбують балончиком чи фарбою кузов; </li>
            <li> розбивають скло або оптику машин;</li>
            <li>
              роблять вм’ятини на дверях (бейсбольними бітами чи каміннями).
            </li>
          </ul>
        </p>
        <img src="./photos/vandalism.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Vandalism</p>
        <h3>WATER/FLOOD (ВОДА/ЗАЛИТІ/УТОПЛЕНИКИ)</h3>
        <p>
          Такі ушкодження бувають двох типів. Перші просто залиті водою машини.
          Наприклад, водій залишив вікно чи люк відчиненими, а вночі пройшов
          сильний дощ. Все, салон зіпсований, власник в паніці дзвонить до
          страхової компанії та просить відшкодувати збитки. Однак електрика та
          механіка можуть бути в належному стані.
        </p>
        <p>
          Другий тип пошкоджень water/flood — авто, які повністю або частково
          тривалий час перебували під водою. Через такий вплив спостерігаються
          серйозні пошкодження:
          <ul>
            <li> електроніки;</li>
            <li> проводки; </li>
            <li> трансмісії;</li>
            <li>двигуна внутрішнього згоряння;</li>
            <li>інших робочих вузлів.</li>
          </ul>
        </p>
        <img src="./photos/water.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Water/Flood</p>
        <p>
          Подібні екземпляри можуть заводитися, однак у 9 із 10 випадків
          повернути працездатність машині вам не вдасться. Навіть якщо
          відновлене авто і поїде, то у невідповідний момент система дасть збій,
          що призведе до аварії.
        </p>

        <h3>HAIL (ГРАД)</h3>
        <p>
          Машини, у списку яких є ця графа, зазнали пошкоджень у результаті
          граду. Все ж таки характер і ступінь завданих негодою збитків може
          відрізнятися. Деякі лоти на аукціонах виглядають наче їх закидали
          камінням.
        </p>
        <p>
          Розглядаємо пропозицію чи шукаємо іншу? Однозначно на це питання
          можуть відповісти лише фахівці. Вони зможуть оцінити технічний стан
          авто із США, а також вартість ремонту.
        </p>
        <img src="./photos/hail.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Hail</p>
        <h3>BURN (ПОЖЕЖА)</h3>
        <p>
          Салон автомобіля чи простір під капотом серйозно постраждали від
          вогню. При цьому продавці вказують, що саме згоріло: двигун/моторний
          відсік (engine), обшивка сидінь/панель приладів (interior) або
          повністю вся машина. Пожежі можуть бути спричинені:
          <ul>
            <li> серйозним ДТП;</li>
            <li> коротким замиканням;</li>
            <li> підпалом.</li>
          </ul>
        </p>
        <img src="./photos/rear.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Burn</p>

        <h3>BIOHAZARD/CHEMICAL (БІОЛОГІЧНА/ХІМІЧНА НЕБЕЗПЕКА)</h3>
        <p>
          Категорія має чимало протиріч, тому що зазвичай її привласнюють через
          сильний неприємний запах усередині машини. Увага, його причиною може
          бути:
          <ul>
            <li> смерть людини або домашньої тварини (трупна отрута);</li>
            <li> розлите пальне (бензин, дизель);</li>
            <li> будівельні матеріали (фарби, розчинники);</li>
            <li> пліснява у салоні.</li>
          </ul>
          <p>
            Придатний до відновлення чи ні? Відповідь безапеляційна — ні. Щоб ви
            не робили, повністю позбутися запаху не вийде, такі закони фізики.
            До того ж він може бути дуже небезпечним для здоров’я.
          </p>
        </p>
        <img src="./photos/bio.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Biohazard/Chemical</p>
        <h3>MECHANICAL (МЕХАНІЧНІ)</h3>
        <p>
          Ушкодження такого плану пов’язані зі зносом чи поломкою основних
          вузлів автомобіля: двигуна, турбіни чи трансмісії. У більшості
          випадків механіка страждає через:
          <ul>
            <li> неакуратну експлуатацію авто;</li>
            <li> невчасне техобслуговування;</li>
            <li> сильний удар при аварії;</li>
            <li> природне зношення.</li>
          </ul>
        </p>
        <img src="./photos/mechanical.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Mechanical</p>
        <h3>MINOR DENTS/SCRATCHES (НЕВЕЛИКІ ВМ’ЯТИНИ/ПОДРЯПИНИ)</h3>
        <p>
          Пробіг таких транспортних засобів порівняно невеликий. Проте вм’ятин і
          подряпин на кузові може бути достатньо. Здебільшого вони з’являються
          тому, що власник неакуратно водить авто чи паркується. Екстремальне
          водіння надає автівці «пошарпаного» вигляду.
        </p>
        <p>
          Коштує ваших грошей чи ні? Мабуть, 50/50. Добре, якщо попередній
          власник був шибайголовою на дорозі. Однак іноді під цим тегом на
          аукціонах ховається справжнісінький автомотлох. Тому лише перевірка
          історії машини через надійні ресурси покаже усі проблеми транспорту.
        </p>
        <img src="./photos/minor.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Minor dents/Scratches</p>
        <h3>PARTIAL/INCOMPLETE REPAIR (ЧАСТКОВИЙ АБО НЕЗАКІНЧЕНИЙ РЕМОНТ)</h3>
        <p>
          Страхові компанії іноді занадто довго займаються відновленням сильно
          розбитих після ДТП машин, але так і не закінчують їхнього ремонту. У
          результаті з фінансових міркувань вони виставляють авто на торги, й
          перекупник:
          <ul>
            <li> купує лот у страхувальника за низькою ціною;</li>
            <li>
              надає йому респектабельного вигляду (часто ремонт поверхневий);
            </li>
            <li> знову продає на торговому майданчику.</li>
          </ul>
        </p>
        <img src="./photos/partial.png" alt="" />
        <p className="photoalt">
          Авто з пошкодженням Partial/Incomplete Repair
        </p>
        <h3>REJECTED REPAIR (ВІДХИЛЕНИЙ РЕМОНТ)</h3>
        <p>
          Лоти з такою відміткою вас точно зацікавлять, адже вони мають
          незвичайний шлях до автоаукціону. Наприклад, власник машини потрапляє
          у ДТП. Страхова компанія оцінює ступінь пошкоджень та пропонує зробити
          ремонт. Кінцевий результат не влаштовує клієнта, і той взагалі
          відмовляється від свого авто. Щоб відшкодувати збитки, агенція
          відправляє автомобіль на торговий майданчик.
        </p>
        <img src="./photos/rejected.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Rejected Repair</p>
        <h3>ROLLOVER (ПЕРЕВОРОТ)</h3>
        <p>
          Автомобілі з таким позначенням побували у серйозних аваріях. Під час
          ДТП машина перекинулася, можливо, кілька разів. Автомеханіки в один
          голос кажуть, що подібні екземпляри дуже складні для відновлення, тому
          що геометрію кузова повністю порушено.
        </p>
        <p>
          Якщо все-таки зважитеся на ремонт такого авто зі США, то вам потрібен
          майстер на всі руки та пристойний капітал. Висновок очевидний. Не
          бажаєте проблем на свою голову — краще знайдіть вигідніший лот. У цій
          справі ми вам допоможемо.
        </p>
        <img src="./photos/roll.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Rollover</p>
        <h3>STRIPPED (РОЗКРАДЕНИЙ)</h3>
        <p>
          Такі транспортні засоби на фото завжди виглядають жахливо, оскільки
          нагадують справжній брухт. У машин з пошкодженнями Stripped в
          результаті викрадення можуть бути відсутні важливі частини кузова чи
          салону:
          <ul>
            <li> двері;</li>
            <li> скло;</li>
            <li> дах;</li>
            <li> сидіння;</li>
            <li> щиток приладів.</li>
          </ul>
        </p>
        <img src="./photos/stripped.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Stripped </p>
        <h3>UNDERCARRIAGE (ПРОБЛЕМИ З ПІДВІСКОЮ, ДНИЩЕМ)</h3>
        <p>
          Найчастіший запит користувачів — що це за пошкодження undercarriage на
          Copart. І все не випадково, адже такі лоти потребують скрупульозної
          перевірки, хоча б тому, що у них частково або повністю розбиті
          елементи днища/підвіски:
          <ul>
            <li> ходова рама (може бути вирвана);</li>
            <li> трансмісія;</li>
            <li> двигун.</li>
          </ul>
        </p>
        <img src="./photos/undercarriage.png" alt="" />
        <p className="photoalt">Авто з пошкодженням Undercarriage </p>
        <h3>RECOVERED THEFTS (ПІСЛЯ ВИКРАДЕННЯ)</h3>
        <p>
          Розділ належить до типу вандалізму. Такі авто було викрадено, але
          потім знайдено поліцією. Оскільки власнику машини вже було
          відшкодовано збитки від страхової компанії, транспортний засіб
          виставляється на автоаукціон. Проблем із документацією та
          експортуванням ТЗ не виникне, тому що після повернення з викрадення
          транспорт ще досі вважається власністю страхової фірми.
        </p>
        <p>
          Купівля чи відмова? Якщо технічний стан машини добрий, то, звичайно,
          варто поторгуватись за цей лот. Це може бути чудовий шанс придбати
          авто зі США, яке не побували у ДТП, та ще й за привабливою ціною.
        </p>
        <h3>MISSING/ALTERED VIN (НЕМАЄ VIN, ЗНИЩЕНИЙ, СПИЛЯНИЙ)</h3>
        <p>
          Автомобілі в цьому розділі є дуже ризикованим варіантом, адже у них
          спиляний/відсутній VIN-код. Значить, ви не дізнаєтесь ні історію авто,
          ні попередніх власників. Причиною видалення ВІН-коду служить
          кримінальне минуле машини, хоча технічний стан може бути одним із
          найкращих на ринку.
        </p>
        <p>
          Брати чи не брати? Сто відсотків, ні. Авто без ідентифікаційного
          номера вам не вдасться ні розмитнити, ні оформити.
        </p>
        <img src="./photos/missing.png" alt="" />
        <p className="photoalt">Авто з Missing/Altered VIN </p>
        <h3>VP-REPLACED VIN (ЗАМІНЕНО VIN-КОД)</h3>
        <p>
          Транспортні засоби з цим пунктом в аукціонній формі потрапляють до
          групи ризику через своє «темне» минуле. Вони належать до проблемних
          лотів, тому що перевірити історію такої машини ви, на жаль, не
          зможете.
        </p>
        <p>
          Варто уваги чи ні? У більшості випадків подібні автомобілі збираються
          з кількох транспортних засобів, тому експерти не рекомендують
          замовляти такі лоти.
        </p>
        <h3>DONATION (ПОЖЕРТВУВАННЯ)</h3>
        <p>
          На торгових майданчиках такі пропозиції викликають особливий інтерес.
          Такі машини йдуть з молотка з мінімальним відсотком пошкоджень і на
          40% дешевші за ринкову вартість. Найчастіше на аукціони вони
          потрапляють із великодушної подачі щедрих парафіян церков чи громад.
        </p>
        <p>
          Цукерка чи пастка? Безперечно, варто взятися за цей лот. Однак
          пристебніться, торги будуть швидкими та спекотними, адже на такі авто
          зі США полюють не лише українці, а й американці.
        </p>
        <h3>COLLISION (ПІСЛЯ ДТП)</h3>
        <p>
          У цій категорії зібрані транспортні засоби, що зазнали пошкоджень при
          аварії. Вдарені машини після зіткнення можуть мати різний характер
          дефектів. Експертна оцінка допоможе вам визначити, чи варто брати
          участь у торгах за такі лоти.
        </p>
        <img src="./photos/collision.png" alt="" />
        <p className="photoalt">Авто після ДТП</p>
      </div>
      <Footer />
    </>
  );
};

export default Article1;
