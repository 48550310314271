import "./App.scss";
import NavBar from "./Components/Navbar/Navbar";
import Header from "./Components/Header/Header";
import Osnovna from "./Components/Main/Osnovna";
import Featured from "./Components/Featured/Featured";
import Services from "./Components/Services/Services";
import WarrantyAndPayment from "./Components/Warranty/WarrantyAndPayment";
import Slider from "./Components/WhyChooseUs/WhyChooseUs";
import Calculator from "./Components/Calculator/Calculator";
import Managers from "./Components/Managers/Managers";
import Footer from "./Components/Footer/Footer";
import LatestArticles from "./Components/LatestArticles/LatestArticles";
import About from "./Components/About/About";
import Steps from "./Components/Steps/Steps";

function App() {
  return (
    <div className="App">
      <Header />
      <NavBar />

      <section id="Home">
        <Osnovna />
      </section>

      <section id="Featured">
        <Featured />
      </section>
      <section id="About">
        <About />
      </section>
      <section id="Services">
        <Services />
      </section>

      <section id="Warranty">
        <WarrantyAndPayment />
      </section>
      <section id="Steps">
        <Steps />
      </section>
      <section id="Comments">
        <Calculator />
        <Slider />
      </section>
      <section id="Contact">
        <Managers />
      </section>
      <section id="Blog">
        <LatestArticles />
      </section>
      <section id="Footer">
        <Footer />
      </section>
    </div>
  );
}

export default App;
