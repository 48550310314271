import "./steps.scss";

import "./responsive.css";
const Steps = () => {
  return (
    <div>
      <section class="b-steps section-default parallax" id="howWework">
        <div class="b-steps__inner">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h2 style={{ padding: 20 + "px" }}>Як це працює</h2>
                <ul class="b-steps-list list-unstyled row">
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">1</span>
                    <div class="b-steps-list__title">ВИБІР</div>
                    <div class="b-steps-list__info">
                      З допомогою менеджера ви можете визначитись щодо марки,
                      моделі, року випуску, та інших бажаних параметрів, які ви
                      хочете бачити у вашому автомобілі.
                    </div>
                  </li>
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">2</span>
                    <div class="b-steps-list__title">БРОНЬ</div>
                    <div class="b-steps-list__info">
                      Ви вносите гарантійний внесок у розмірі 400$ або 10% від
                      прогнозованої вартості авто, яке ми будем купувати. Цей
                      внесок буде врахований у вартість автомобіля, і є
                      обов’язковим для участі в торгах.
                    </div>
                  </li>
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">3</span>
                    <div class="b-steps-list__title">ТОРГИ</div>
                    <div class="b-steps-list__info">
                      Ми виграємо для вас автомобіль на аукціоні і надаємо
                      рахунок для оплати авто та доставки його в порт Одеса (або
                      один із портів Європи)
                    </div>
                  </li>

                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">4</span>
                    <div class="b-steps-list__title">ДОСТАВКА В ПОРТ</div>
                    <div class="b-steps-list__info">
                      Після надходження оплати, ми організовуємо доставку
                      автомобіля на склад в порт відправки. Додатково
                      фотографуємо авто та висилаємо фото вам.
                    </div>
                  </li>
                </ul>
                <ul class="b-steps-list list-unstyled row">
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">5</span>
                    <div class="b-steps-list__title">ВІДПРАВКА МОРЕМ</div>
                    <div class="b-steps-list__info">
                      Авто грузиться в контейнер разом з іншими автомобілями та
                      відправляється у вибраний вами порт.
                    </div>
                  </li>
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">6</span>
                    <div class="b-steps-list__title">РОЗВАНТАЖЕННЯ</div>
                    <div class="b-steps-list__info">
                      В порту прибуття ваш автомобіль розвантажується. Робиться
                      ще один комплект фото.
                    </div>
                  </li>
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">7</span>
                    <div class="b-steps-list__title">РОЗМИТНЕННЯ</div>
                    <div class="b-steps-list__info">
                      Брокер готує всі необхідні документи для розмитнення
                      автомобіля, надає квитанцію для оплати митних зборів і вам
                      доставлять вже повністю розмитнений автомобіль.
                    </div>
                  </li>
                  <li class="b-steps-list__item col-lg">
                    <span class="b-steps-list__number">8</span>
                    <div class="b-steps-list__title">ДОСТАВКА У ВАШЕ МІСТО</div>
                    <div class="b-steps-list__info">
                      Ми доставляємо авто в ваше місто.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Steps;
