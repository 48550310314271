import "./services.scss";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { HashLink } from "react-router-hash-link";
// ..
AOS.init();

const Services = () => {
  return (
    <div className="serviceswrapper">
      <div
        className="servicesLeft"
        data-aos="fade-right"
        data-aos-offset="200"
        data-aos-delay="100"
        data-aos-duration="20000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-center"
      >
        <h2>Що ми пропонуємо</h2>
        <span>
          Наша компанія займається доставкою та підбором автомобілів з США та
          Канади. Ціль нашої компанії спростити купівлю та доставку авто з США в
          Україну.
        </span>
        <div className="readMore">
          <a href="/final">
            <HashLink smooth to="/#Steps" className="unlink">
              Детальніше
            </HashLink>
          </a>
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="1000"
        data-aos-duration="10000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-center"
        className="servicesRight"
      >
        <div className="servicetop">
          <div className="service">
            <div className="logoandname">
              <img src="./key.png" alt="" className="servicelogo" />
              Послуга під ключ
            </div>
            <br />
            Наша компанія співпрацює, щонайменше, з 5 найкращими автосервісами у
            Львові. Наші менеджери постійно вивчають ринок, та знайдуть для Вас
            якісні запчастини по найвигіднішій ціні. Також можемо доставити вже
            готовий автомобіль у ваше місто.
          </div>
          <div className="service">
            {" "}
            <div className="logoandname">
              <img src="./servicehistory.png" alt="" className="servicelogo" />
              Перевірка стану та сервісної історії авто на платних платформи.
            </div>
            <br />
            Використовуємо платні сервіси для перевірки транспортного засобу в
            кілька етапів(к-сть власників, історія дтп та обслуговування, наявні
            пошкодження та актуальний пробіг).
          </div>
          <div className="service">
            <div className="logoandname">
              <img src="./delivery.png" alt="" className="" />
              Розмитнення та доставка авто до України.
            </div>
            <br />
            Завдяки налагодженій логістиці, транспортування автомобілів
            здійснюється на високому рівні та за доступними розцінками.Доставимо
            транспортний засіб до України виключно законним шляхом. А також
            страхуємо Ваш автомобіль в дорозі.
          </div>
        </div>
        <div className="servicebottom">
          <div className="service">
            <div className="logoandname">
              <img src="./choose.png" alt="" className="servicelogo" />
              Підбір авто за вашими критеріями.
            </div>

            <ul>
              Підберемо з аукціону авто:
              <li>
                за вашим бюджетом та побажаннями(колір кузова та салону,
                комплектація)
              </li>
              <li>з мінімальними пошкодженнями</li>
              <li>з невеликим пробігом</li>
              <li>без кримінального минулого</li>
            </ul>
          </div>
          <div className="service">
            {" "}
            <div className="logoandname">
              <img src="./auction.png" alt="" className="" />
              Перевірка стану та сервісної історії авто на платних платформи.
            </div>
            <br />
            Використовуємо платні сервіси для перевірки транспортного засобу в
            кілька етапів(к-сть власників, історія дтп та обслуговування, наявні
            пошкодження та актуальний пробіг).
          </div>
          <div className="service">
            <div className="logoandname">
              <img src="./detailing.png" alt="" className="" />
              Детейлінг
            </div>
            <br />
            Наша компанія з великою увагою та професіоналізмом надає послуги
            детейлінгу, розкриваючи справжню красу та блиск вашого автомобіля до
            найвищих стандартів догляду.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
