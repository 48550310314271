import "./footer.scss";
import { HashLink } from "react-router-hash-link";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_oq9zdse", "template_m4lq6jq", form.current, {
        publicKey: "iqy55FwSZWdRgpOpg",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          e.target.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="footer">
      <div className="topFooter">
        <section className="contactInfo">
          <h3>Контакти</h3>
          <div className="links">
            <div className="link">
              <img src="./phone.png" alt="" className="icons" />
              <span>
                <a href="tel:+38(099)9856515">+38 (099) 985 6515</a>
              </span>
            </div>
            <div className="link">
              <img src="./mail.png" alt="" className="icons" />
              <span>
                <a href="mailto:westautogrp@gmail.com">westautogrp@gmail.com</a>
              </span>
            </div>
            <div className="link">
              <img src="./location.png" alt="" className="icons" />
              <span>
                <a href="https://g.co/kgs/gu74DU">м. Львів вул.Дорошенка,67</a>
              </span>
            </div>
          </div>
        </section>
        <section className="usefulLinks">
          <h3>Корисні посилання</h3>
          <div className="links">
            <a href="/">Головна</a>
            <a href="/final" className="locallink">
              <HashLink smooth to="/#About" className="hashlinkk">
                Про нас
              </HashLink>
            </a>
            <a href="/final" className="locallink">
              <HashLink smooth to="/#Services" className="hashlinkk">
                Послуги
              </HashLink>
            </a>
            <a href="/final" className="locallink">
              <HashLink smooth to="/#Featured" className="hashlinkk">
                Популярні запити
              </HashLink>
            </a>
            <a href="/final" className="locallink">
              <HashLink smooth to="/#Contact" className="hashlinkk">
                Наші менеджери
              </HashLink>
            </a>
            <a href="/final" className="locallink">
              {" "}
              <HashLink smooth to="/#Comments" className="hashlinkk">
                Відгуки
              </HashLink>
            </a>
            <a href="/final" className="locallink">
              <HashLink smooth to="/#Warranty" className="hashlinkk">
                Гарантії та оплата
              </HashLink>
            </a>
          </div>
        </section>

        <section className="contact">
          <h3>Зворотній зв'язок</h3>

          <form ref={form} onSubmit={sendEmail} className="contactForm">
            <input
              type="text"
              name="user_name"
              id="user_name"
              placeholder="Ваше ім'я"
              required
            />

            <input
              type="tel"
              name="user_email"
              id="user_email"
              placeholder="Ваш номер телефону"
              required
            />

            <textarea name="message" placeholder="Повідомлення" />
            <input type="submit" value="Надіслати" />
          </form>
        </section>
      </div>
      <hr className="botline" />
      <div className="bottomFooter">
        <div className="left">© 2024 Pan American. All Right Reserved.</div>
        <div className="right">
          <a
            href="https://instagram.com/pan.a.merican?igshid=NzZlODBkYWE4Ng=="
            className="social"
          >
            <img src="./instagram.png" alt="" className="socicon" />
          </a>
          <a
            href="https://auto.ria.com/uk/avto-pod-zakaz/pan-american/3268/"
            className="social"
          >
            <img src="autoria.png" alt="" className="socicon" />
          </a>
          <a
            href="https://www.tiktok.com/@pan.a.merican?_t=8hYAcuu6n5y&_r=1"
            className="social"
          >
            <img src="./tiktok.png" alt="" className="socicon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
