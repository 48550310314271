import "./Article2.scss";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Article2 = () => {
  return (
    <>
      <Header />
      <Navbar />
      <div className="article2wrap">
        <h2 style={{ textAlign: "center" }}>
          Як перевірити авто зі США перед покупкою
        </h2>
        <p className="articletext">
          Якщо ви не перевірите авто із США за VIN-кодом то вас чекають сюрпризи
          чи навіть серйозні проблеми під час розмитнення. Дізнайтеся, що таке
          ВІН-код, які дані включає та на яких сервісах його перевіряють.
        </p>
        <p className="articletext">
          Не все те золото, що блищить. Іноді це стосується і машин, які
          виставлені на автоаукціонах, адже світ не без шахраїв. Нечесні
          продавці намагаються приховати реальний технічний стан або кримінальну
          історію транспортного засобу. Щоб уникнути подібних сюрпризів під час
          розмитнення, вам потрібно дізнатися, як перевірити машину за ВІН-кодом
          з Америки. Таке тестування допоможе не купити повністю вбитий
          автомобіль за космічні гроші. Але для початку ознайомтеся з тим, як
          вибрати авто із США. Визначившись з вибором, починайте ретельне
          дослідження лота, що сподобався.
        </p>
        <h3>ЩО ТАКЕ VIN-КОД ТА ДЕ ВІН ЗНАХОДИТЬСЯ?</h3>
        <p className="articletext">
          Абревіатура VIN з англійської мови розшифровується як Vehicle
          Identification Number. На виробництві кожному окремому транспортному
          засобу надається унікальний ідентифікаційний номер. Він складається з
          17 буквених та цифрових знаків. Щоб уникнути плутанини, в ньому не
          використовуються такі літери, як I, O і Q через свою схожість з
          деякими цифрами. Унікальний код складається із чотирьох частин:
          <ol>
            <li>
              Індекс виробника (1-3 позиції). Код країни, назва компанії, номер
              виробничого відділу.
            </li>
            <li>
              Комплектація та характеристика ТЗ (4-8 позиції). Модель, тип
              кузова, двигун, трансмісія автомобіля.
            </li>
            <li>
              Контрольний номер (9 позиція). Ідентифікаційні дані (10-11
              позиції): рік випуску, код заводу збирання.
            </li>
            <li>Останні 5 цифр (12-17 позиції) — серійний номер авто.</li>
          </ol>
        </p>
        <p className="articletext">
          Так, лише в одному кодові виробник зашифрував основні відомості про
          транспортний засіб. За цим номером нескладно отримати всі необхідні
          відомості про ТЗ, що пояснює,чому зростає популярність авто з США.
          Використання цього шифру також полегшує роботу багатьох сервісів.
          ВІН-код вноситься до єдиного реєстру Державного департаменту,
          наприклад Сполучених Штатів, а в Україні — до бази даних МВС. Знайти
          ідентифікаційний номер на автомобілі ви можете:
          <ul>
            <li>під капотом на передній частині двигуна;</li>
            <li>внизу вітрового скла навпроти панелі приладів;</li>
            <li>на дверній рамі (стійці) зі сторони дверей водія;</li>
            <li>під обшивкою підлоги під водійським кріслом.</li>
          </ul>
        </p>
        <p className="articletext">
          Відповідно до стандартів виробництва, VIN-код вибивається спеціальною
          установкою на нероз’ємних частинах кузова чи шасі на шильдиках. До
          того ж ідентифікатор вказується в технічному паспорті машини,
          документах про реєстрацію, страховому полісі та посібнику користувача,
          якщо такий зберігся.
        </p>
        <h3>ДІЗНАЙТЕСЬ МИНУЛЕ МАШИНИ ЗА VIN-КОДОМ</h3>
        <p className="articletext">
          На аукціонах Америки найчастіше виставляються побиті автомобілі після
          страхового випадку. Деякі з них відрізняються серйозними ушкодженнями,
          тому на торгах мають дуже низькі ціни. Кмітливі перекупники скуповують
          їх, «підмальовують губи» та повторно виставляють на майданчиках за
          привабливою вартістю. Для недосвідчених покупців такі лоти стають
          пасткою, але тільки тоді, коли вони не знаються на розшифрування
          пошкоджень на аукціонах та інших позначеннях. Однак, якщо ви знаєте,
          як перевірити авто із США за ВІН-кодом, то відразу викриєте таку
          пропозицію. Скориставшись VIN-декодером, ви отримаєте детальну
          інформацію про обраний автомобіль:
          <ol>
            <li>Список усіх власників.</li>
            <li>
              Дані про встановлення на облік та зняття, продажі та заміну
              техпаспорту.
            </li>
            <li>
              Інформацію про комплектацію та технічний стан: пробіг, вік,
              пошкодження, ремонтні роботи, заміна деталей або несправність
              основних вузлів.
            </li>
            <li>Наявність штрафів, а також чи знаходиться машина в розшуку.</li>
            <li>
              Участь у ДТП зі всіма подробицями та навіть фотозвіти після кожної
              аварії.
            </li>
          </ol>
        </p>
        <p className="articletext">
          Сервери перевірки надають розширений звіт за датами, але тільки якщо
          ремонтні роботи та сервісне обслуговування зафіксовані документально.
          Проте деякі сумніваються в правдивості наданих відомостей.
        </p>
        <p className="articletext">
          Перевірка транспортного засобу за ВІН-кодом дозволяє простежити за
          всіма змінами, які були здійснені в автомобіль під час експлуатації.
          Понад того, декодер відкриває фальсифіковані VIN-коди. Таким чином,
          завдяки отриманим даним ви не купите крадену чи потенційно небезпечну
          машину. Пам’ятайте, що транспортний засіб, який побував у серйозних
          ДТП, може мати непоправні пошкодження.
        </p>
        <h3>ЯК ПЕРЕВІРИТИ ІСТОРІЮ АВТО ІЗ США САМОСТІЙНО?</h3>
        <p className="articletext">
          Найвідомішим сервером перевірки вважається інтернет-платформа CARFAX.
          У цій загальнонаціональній базі Америки збираються всі дані про
          зареєстровані на території Сполучених Штатів та Канади ТЗ. Розуміючи,
          що таке звіт CARFAX, ви зможете правильно його прочитати та
          зосередитися на пунктах, які вам насправді потрібні для покупки авто
          із США. На прикладі Карфакс ми покажемо, як отримати відомості про
          минуле транспортного засобу:
          <ul>
            <li>зайдіть на сайт CARFAX (або іншого сервера);</li>
            <li>введіть у пошуковому рядку ВІН-код машини;</li>
            <li>виберіть та сплатіть відповідний тариф;</li>
            <li>уважно дослідіть звіт.</li>
          </ul>
        </p>
        <p className="articletext">
          Декодер надасть вам детальну інформацію про вибраний автомобіль, а
          також фото після кожної ДТП, яких часто немає у відкритому доступі. У
          цьому звіті зверніть увагу на записи Damage reported, тому що вони
          допоможуть виявити кілька ризиків, пов’язаних з покупкою авто. Також
          не ігноруйте повідомлення з написами Accident reported (аварії),
          «ALERT!» та Odometer reading reported (пробіг ТЗ). Уважно ознайомтеся
          з усіма розділами:
          <ol>
            <li>Branded Title. Технічний стан, випадки ДТП.</li>
            <li>Damage reported. Звіт про ушкодження.</li>
            <li>Previous owners. Попередні власники.</li>
            <li>
              At least. Випадки, коди компанія-виробник ремонтувала авто через
              несправності.
            </li>
            <li>Types of owners. Тип володіння ТЗ.</li>
            <li>
              Last reported odometer reading. Останній зафіксований пробіг.
            </li>
          </ol>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Article2;
