import "./featured.scss";
import RecentCarCard from "../RecentCarCard/RecentCarCard";

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const Featured = () => {
  const cars = [
    {
      photo: "./photos/audiq52013.jpg",
      price: "9250",
      brand: "Audi Q5",
      volume: "2.0 л.",
      fuel: "Бензин",
      miles: "77",
      transmission: "Автомат",
      year: "2013",
    },
    {
      photo: "./photos/audia62016.jpg",
      price: "12200",
      brand: "Audi A6",
      volume: "2.0 л.",
      fuel: "Бензин",
      miles: "65",
      transmission: "Автомат",
      year: "2016",
    },
    {
      photo: "./photos/bmw5282017.jpg",
      price: "11800",
      brand: "BMW 528 XI",
      volume: "2.0 л.",
      fuel: "Бензин",
      miles: "85",
      transmission: "Автомат",
      year: "2017",
    },

    {
      photo: "./photos/rogues2019.jpg",
      price: "11 000",
      brand: "Nissan Rogue S",
      volume: "2.5 л.",
      fuel: "Бензин",
      miles: "50,8",
      transmission: "Автомат",
      year: "2019",
    },
    {
      photo: "./photos/bmwx52016.jpg",
      price: "14 975",
      brand: "BMW X5",
      volume: "3.0 л.",
      fuel: "Бензин",
      miles: "102",
      transmission: "Автомат",
      year: "2016",
    },
    {
      photo: "./photos/teslamodel32022.jpg",
      price: "21 510",
      brand: "Tesla Model 3",
      volume: "60 кВт ",
      fuel: "Електро",
      miles: "8,7",
      transmission: "",
      year: "2022",
    },
    {
      photo: "./photos/escape2018.jpg",
      price: "8935",
      brand: "Ford Escape",
      volume: "1.5 л.",
      fuel: "Бензин",
      miles: "86",
      transmission: "Автомат",
      year: "2018",
    },

    {
      photo: "./photos/a4premium.jpg",
      price: "16100",
      brand: "Audi A4 Premium Plus",
      volume: "2.0 л.",
      fuel: "Бензин",
      miles: "32",
      transmission: "Автомат",
      year: "2019",
    },
    {
      photo: "./photos/jettas19.jpg",
      price: "10450",
      brand: "Volkswagen Jetta S",
      volume: "1.4 л.",
      fuel: "Бензин",
      miles: "28",
      transmission: "Автомат",
      year: "2019",
    },
    {
      photo: "./photos/nx200t.jpg",
      price: "18700",
      brand: "Lexus NX 200T",
      volume: "2.0л",
      fuel: "Бензин",
      miles: "74",
      transmission: "Автомат",
      year: "2019",
    },
    {
      photo: "./photos/e3002018.jpg",
      price: "18700",
      brand: "Mercedes-Benz E300",
      volume: "2.0л",
      fuel: "Бензин",
      miles: "48",
      transmission: "Автомат",
      year: "2018",
    },
    {
      photo: "./photos/x52016.jpg",
      price: "14900",
      brand: "BMW X5",
      volume: "3.0л",
      fuel: "Бензин",
      miles: "97",
      transmission: "Автомат",
      year: "2018",
    },
    {
      photo: "./photos/sportage2023.jpg",
      price: "20900",
      brand: "KIA Sportage",
      volume: "2.5л",
      fuel: "Бензин",
      miles: "17",
      transmission: "Автомат",
      year: "2023",
    },
    {
      photo: "./photos/mustang2015.jpg",
      price: "13200",
      brand: "Ford Mustang",
      volume: "2.3л",
      fuel: "Бензин",
      miles: "73",
      transmission: "Автомат",
      year: "2015",
    },
    {
      photo: "./photos/tiguan2019.jpg",
      price: "18700",
      brand: "Volkswagen Tiguan SE",
      volume: "2.0л",
      fuel: "Бензин",
      miles: "74",
      transmission: "Автомат",
      year: "2019",
    },
  ];

  return (
    <>
      <div className="featuredWrapper">
        <h2>Вибір наших клієнтів</h2>
        <>
          <Swiper
            slidesPerView={
              window.screen.width < 736
                ? 1
                : (window.screen.width > 736) & (window.screen.width < 980)
                ? 2
                : 3
            }
            spaceBetween={40}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Autoplay, Navigation]}
            className="mySwiper"
          >
            {cars.map((car, index) => (
              <SwiperSlide>
                <RecentCarCard key={index} {...car} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      </div>
    </>
  );
};

export default Featured;
