import "./about.scss";

const About = () => {
  return (
    <div
      className="aboutwrapper"
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="aboutleft">
        <h2>Про "Pan American"</h2>
        <div className="tag">
          <p style={{ padding: 15 + "px" }}>
            Ми зробимо автомобіль з Сполучених Штатів доступним кожному
            українському водію.
          </p>
        </div>
        <div className="aboutinfo">
          <p>
            Наш офіс знаходить у м. Львів. Проте ми працюємо по всій Україні. В
            разі, якщо Ви не маєте змоги відвідати наш офіс, співпраця проходить
            дистанційно(договір відправляємо Новою Поштою, страховий платіж
            надсилаєте на ФОП рахунок, оплата авто відбувається через KitGroup)
            Офіційний представник Copart У нас є ліцензія Copart, яка надає нам
            особливі можливості аукціону та знімає обмеження. Нашим менеджерам
            доступні закриті лоти й детальна інформація про авто. Підберемо з
            аукціону, та доставимо автомобіль для особистого користування,
            сімейне авто чи авто для бізнесу. Знаходимо варіанти:
            <br /> •по доступній ціні; <br /> •з мінімальними пошкодженнями;{" "}
            <br /> •з оптимальним, а головне актуальним пробігом; <br />
            •з недорогим ремонтом; •без кримінального минулого. <br /> <br />
            Ми працюємо не на кількість, а на якість. Готові торгувати для Вас
            автомобілі навіть вночі, для того щоб забрати найкращий лот. Завдяки
            налагодженій логістиці, транспортування автомобілів здійснюється на
            високому рівні та за доступними розцінками. Завдяки цьому вартість
            машини зі штатів обійдеться вам на 30-40% дешевше аналогічної моделі
            на українському авторинку.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
