import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  FaCar,
  FaTruck,
  FaCog,
  FaMoneyBillWave,
  FaShieldAlt,
  FaLeaf,
  FaHeadset,
  FaHandshake,
} from "react-icons/fa"; // Іконки можна замінити на необхідні
import "./WhyChooseUs.scss";
const Slider = () => {
  const slides = [
    {
      id: 1,
      text: "Широкий вибір автомобілів",
      icon: <FaCar />,
      explanation: "Найбільший вибір автомобілів для будь-яких потреб",
    },
    {
      id: 2,
      text: "Швидка та надійна доставка",
      icon: <FaTruck />,
      explanation: "Швидка та безпечна доставка до ваших дверей",
    },
    {
      id: 3,
      text: "Професійний підбір авто під ваші потреби",
      icon: <FaCog />,
      explanation:
        "Команда експертів готова допомогти вам обрати ідеальне авто",
    },
    {
      id: 4,
      text: "Гнучкі умови фінансування",
      icon: <FaMoneyBillWave />,
      explanation: "Ви не оплачуєте повну суму одразу",
    },
    {
      id: 5,
      text: "Сертифіковані б/у автомобілі з гарантією",
      icon: <FaShieldAlt />,
      explanation:
        "Великий вибір сертифікованих автомобілів з офіційною гарантією",
    },
    {
      id: 6,
      text: "Екологічно чисті автомобілі",
      icon: <FaLeaf />,
      explanation: "Пропонуємо електромобілі та авто з низьким рівнем викидів",
    },
    {
      id: 7,
      text: "Зручний онлайн сервіс підтримки",
      icon: <FaHeadset />,
      explanation: "Онлайн консультація та підтримка клієнтів",
    },
    {
      id: 8,
      text: "Перевірені партнери та постачальники",
      icon: <FaHandshake />,
      explanation: "Співпраця з надійними постачальниками та партнерами",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const slideProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    reset: true,
  });

  return (
    <>
      <h2 className="titlename">Чому клієнти обирають нас</h2>

      <div className="slider">
        <animated.div className="slider-content" style={slideProps}>
          <h2>{slides[currentSlide].text}</h2>
          <div className="icon">{slides[currentSlide].icon}</div>
          <p>{slides[currentSlide].explanation}</p>
        </animated.div>
      </div>
    </>
  );
};

export default Slider;
