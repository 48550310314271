import "./managers.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaViber } from "react-icons/fa";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Navigation } from "swiper/modules";

const Managers = () => {
  return (
    <>
      <h2 style={{ textAlign: "center" }}>Наша команда</h2>
      <div className="managersWrapper ">
        <>
          <Swiper
            slidesPerView={window.screen.width < 780 ? 1 : 2}
            spaceBetween={40}
            navigation={true}
            pagination={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager_main.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1>
                        Булатецький Артем Артемович <br />
                        <span>Засновник Pan American</span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0999856515
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0999856515
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager1.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1>
                        Андрощук Дмитро <br />
                        <span>Керівник відділу продажів</span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0977239366
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0977239366
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager6.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1>
                        Крилас Максим <br />
                        <span>Керівник відділу логістики</span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0501550530
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0501550530
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager7.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1>
                        Слободянюк Назар <br />
                        <span>Старший менеджер з питань комунікації </span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0634338206
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0634338206{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager3.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1 style={{ fontSize: 18 + "px" }}>
                        Шидловський Віталій <br />
                        <span>Менеджер</span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0681760226
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0681760226
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager4.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1>
                        Антоненко Роман <br />
                        <span> Менеджер</span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0689932700{" "}
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0689932700
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="managerSlide">
              <div className="manager">
                <div class="managerCard">
                  <div class="image">
                    <img src="./manager5.jpg" />
                  </div>
                  <div class="details">
                    <div class="center">
                      <h1>
                        Пасічний Валентин <br />
                        <span> Менеджер</span>
                      </h1>
                      <p>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaPhoneAlt className="mnicon" />
                          0958660207
                        </a>
                        <a className="managerLink" href="callto:09622266147">
                          {" "}
                          <FaViber className="mnicon" />
                          0958660207
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      </div>
    </>
  );
};

export default Managers;
