import "./warranty.scss";

const WarrantyAndPayment = () => {
  return (
    <div className="warrantywrapper">
      <h2>Гарантії та оплата</h2>
      <div className="warrantyleft">
        <h3>Гарантія</h3>
        Наша компанія Pan American працює для Вас.
        <br />
        <br /> Можливо доставка авто з США може здаватись ризикованою. Чи хтось
        з Ваших знайомих мав негативний досвід в цій справі. Ми надаємо
        гарантії, саме для цього складаємо договір, який є юридично завіреним.
        <br />
        <br />
        Доставимо саме той автомобіль, який Ви обрали.
        <br />
        <br /> Надаємо всю історію на авто від страхової компанії.
        <br />
        <br />
        <img src="./carfax.png" alt="" className="fax " />
        <br />
        <br />
        Доставимо транспортний засіб до України виключно законним шляхом.
        <br />
        <br />
        Страхуємо Ваш автомобіль в дорозі. Ми розуміємо, що можуть статись
        непередбачені ситуації, тому страхівка готова покрити додаткові витрати.
        Ми працюємо офіційно за договором з клієнтом. На підставі страхового
        платежу у розмірі 10% від ціни автомобіля. Після покупки авто на
        аукціоні, робиться кінцевий прорахунок виграного лоту, який додається до
        договору.
      </div>
      <div className="paymentRight">
        <h3>Оплата</h3>
        Оплата за авто відбувається в 3 етапи:
        <br />
        <br /> 1)Протягом 1-2 днів після покупки авто з аукціону потрібно внести
        перший платіж, який включає в себе:
        <br />
        <br /> Вартість авто з аукціону + Аукційний збір + Страхівка(За бажанням
        клієнта) + Сторедж. <br />
        <br />
        *Це міжнародний переказ. У зв'язку з забороною Swift-переказів в Україні
        ви можете здійснити цей платіж двома способами:
        <br />
        <br /> Через власний закордонний рахунок, якщо маєте такий. В такому
        разі ви відповідаєте за своєчасну оплату. Якщо оплата не надійде вчасно
        - нараховується додатковий сторедж.
        <br />
        <br /> Через наш логістичний центр. В такому випадку додатково
        оплачується комісія за міжнародний переказ в розмірі 5%.
        <br /> <br />
        2) Протягом 2-4 тижнів з моменту першої оплати, потрібно буде внести
        другий платіж за логістичні послуги: Доставка до Литви + Експедиція.{" "}
        <br />
        <br />
        *Це міжнародний переказ. У зв'язку з забороною Swift-переказів в Україні
        ви можете здійснити цей платіж двома способами: <br />
        <br />
        Через власний закордонний рахунок, якщо маєте такий. В такому разі ви
        відповідаєте за своєчасну оплату. Якщо оплата не надійде вчасно -
        нараховується додатковий сторедж. <br />
        <br />
        Через наш логістичний центр. В такому випадку додатково оплачується
        комісія за міжнародний переказ в розмірі 5%. <br />
        <br />
        3) Коли авто під'їжджає до кордону потрібно внести третій платіж за
        розмитнення та інші послуги: Брокерські послуги + Погрузка автомобіля +
        Мито + Доставка до Львова. <br />
        <br />
        Цей платіж сплачується будь-яким банком України, тому може бути комісія
        лише згідно з умовами і тарифами вашого банку. <br />
        <br />
        Також є додаткові послуги з ремонту "Під ключ"(Вартість послуги 200$) та
        доставка автомобіля по Україні.
      </div>
    </div>
  );
};

export default WarrantyAndPayment;
