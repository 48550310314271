import "./recentCarCard.scss";

const RecentCarCard = ({
  photo,
  price,
  brand,
  volume,
  fuel,
  miles,
  transmission,
  year,
}) => {
  return (
    <div className="recentCarWrapper">
      <img src={photo} className="bgimg" />
      <div className="recentcarinfo">
        <div className="price caryear">
          {price <= 9500 ? "$" : price >= 10000 && price < 15000 ? "$$" : "$$$"}
        </div>
        <div className="price caryear pricekey">
          Ціна під ключ: <div className="priceline" />
          <span>$ {price}</span>
        </div>
        <div className="carBrandlogo">
          <div className="carbrend">{brand}</div>
          <div className="carinfo">
            {volume} | {fuel} | {miles} тис. миль | {transmission}
          </div>
          <img src="./logo-sm.png" alt="aaa" className="logosm" />
          <div className="caryear">{year}</div>
        </div>
      </div>
    </div>
  );
};

export default RecentCarCard;
