// Article.js
import React from "react";
import "./Article3.scss";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Article3 = () => {
  return (
    <>
      <Header />
      <Navbar />
      <div className="article3wrap">
        <h2 style={{ textAlign: "center" }}>
          Чи варто купувати биті авто зі США?
        </h2>
        <p className="articletext">
          Якщо ви не перевірите авто із США за VIN-кодом то вас чекають сюрпризи
          чи навіть серйозні проблеми під час розмитнення. Дізнайтеся, що таке
          ВІН-код, які дані включає та на яких сервісах його перевіряють.
        </p>
        <div
          style={{
            width: "700" + "px",
            objectFit: "cover",
            height: "465" + "px",
            overflow: "hidden",
            margin: "0",
            alignSelf: "center",
          }}
        >
          <img
            src="./article3_1.jpg"
            alt="articleIMG"
            className="articleimg"
            style={{
              width: "100" + "%",
              objectFit: "contain",

              overflow: "hidden",
              margin: "0",
              alignSelf: "center",
            }}
          />
        </div>
        <p className="articletext">
          Привезення машин зі Сполучених Штатів популярне у всьому світі. Однак
          у вас може виникнути безліч сумнівів, чи варто купувати авто зі США.
          Більшість транспортних засобів, привезених через океан, биті та
          потребують ремонту. Деякі кажуть, що це справжній автомотлох й немає
          сенсу вкладати гроші в їх відновлення. Простіше купити автомобіль на
          вітчизняному ринку, завестися й одразу поїхати. Але знаєте, це лише з
          одного боку здається, що це істина - бо ще немає обгрунтованої іншої
          думки. Вважайте, ми тут для того, щоб розвіяти міфи про биті машини.
        </p>
        <h3>ВСЯ ПРАВДА ПРО АВТО ІЗ США</h3>
        <p className="articletext">
          Більшість транспортних засобів, які привозяться до України, купуються
          на американських аукціонах. Там страхові компанії виставляють лоти
          після страхового випадку. Зазвичай, від них відмовляються власники,
          адже їм виплачується пристойна компенсація. Це одна з причин, чому на
          аукціонах багато битих машин. Більш того, в Америці не заведено
          відновлювати автомобілі після ДТП, адже:
          <ul>
            <li>це занадто дорого, якщо робити ремонт у Штатах;</li>
            <li>за страхові виплати можна придбати нове авто;</li>
            <li>американці змінюють транспортні засоби кожні 3-5 років.</li>
          </ul>
        </p>
        <p className="articletext">
          На торгових майданчиках ви знайдете не лише биті екземпляри.
          Наприклад, аукціон Manheim виставляє вживані або майже нові
          транспортні засоби без пошкоджень. Звичайно, бувають винятки,
          наприклад, лоти, що мають зіпсоване крило або вм’ятини після дотичного
          удару.
        </p>
        <p className="articletext">
          Торговий майданчик Іншуренс пропонує широкий вибір транспортних
          засобів. Однак найпопулярніший аукціон битих авто в США — це Copart.
          Щодня на майданчик привозяться тисячі ТС з різним ступенем ушкоджень.
        </p>{" "}
        <p className="articletext">
          Висновок такий. Не всі автомобілі з Америки вбиті та вважаються
          автомотлохом. Насправді мільйони українців уже пересіли на
          американські машини й без проблем підкоряють дороги України.
        </p>
        <h3>ЧИ ВАРТО БРАТИ АВТО ПІСЛЯ ДТП?</h3>
        <p className="articletext">
          Однозначної відповіді на поставлене запитання немає. Кожен окремий
          випадок слід розглядати в індивідуальному порядку. Зараз пояснимо
          чому. На торгових майданчиках Copart та IAAI виставляються автомобілі,
          які побували в аваріях. Ступінь та характер пошкоджень залежить від
          особливостей ДТП, а також отриманого удару, який:
          <ul>
            <li>зачепив чи ні робочі вузли машини;</li>
            <li>прийшовся на ходову чи днище;</li>
            <li>деформував чи ні геометрію кузова.</li>
          </ul>
        </p>
        <p className="articletext">
          Серйозність деяких пошкоджень з першого погляду не така очевидна. Не є
          виключеними випадки, коли продавці маскують серйозні пошкодження
          транспортних засобів, щоб замаскувати серйозні недоліки. Але ви
          повинні знати, чим ви ризикуєте, купуючи такі лоти. Якщо не “пробити”
          авто зі США за VIN-кодом, «кіт у мішку» вам гарантовано. Проте для
          відновлення в Україні підійдуть автомобілі з пошкодженнями таких типів
          :
          <ul>
            <li>Rear End (задня частина);</li>
            <li>Normal Wear (природне зношування);</li>
            <li>Vandalism (вандалізм);</li>
            <li>Hail (град);</li>
            <li>Minor dents/Scratches (невеликі вм’ятини/подряпини);</li>
            <li>Rejected Repair (відхилений ремонт);</li>
            <li>Recovered Thefts (після викрадення);</li>
            <li>Donation (пожертвування)</li>
          </ul>
        </p>
        <p className="articletext">
          Однак це не означає, що лоти з наведеними вище позначеннями не
          потребують додаткової перевірки. Експертна оцінка та звіти CARFAX
          покаже справжній технічний стан авто та його історію. Дізнавшись
          ступінь дефектів, проконсультуйтеся з автомеханіками, скільки
          коштуватиме ремонт такої пригнаної з Америки машини. І тільки
          розрахувавши всі витрати, погоджуйтесь на цю пропозицію. Наш
          онлайн-калькулятор допоможе вам провести всі потрібні розрахунки.
        </p>
        <p className="articletext">
          Трохи кмітливості, талановитий автомеханік та зв’язки допоможуть вам
          провернути найвигіднішу справу вашого життя. Але без «секретних
          матеріалів» вам ніяк не обійтися. Відкриємо вам кілька козирів
          того,вибрати вживаний автомобіль . Наприклад, торгові майданчики
          застосовують спеціальні системи позначень. Навчившись правильно читати
          аукціонні листи лотів, ви не купите проблемного транспортного засобу,
          який не підлягає ремонту. Спочатку дізнайтеся, з якими саме
          екземплярами краще не зв’язуватися.
        </p>
        <h3>АВТО ІЗ США, ЯКІ НЕОБХІДНО КУПУВАТИ З ОБЕРЕЖНІСТЮ</h3>
        <p className="articletext">
          Покажемо вам другий бік медалі заокеанських автомобілів. Оскільки
          характер отриманих внаслідок ДТП дефектів занадто широкий (від
          незначного до тотального), вибір може бути непростим. Проте існують
          пошкодження, з якими дійсно не слід купувати транспортні засоби. Ось
          топ-10 найризикованіших варіантів на американських аукціонах:
          <ol type="1">
            <li>
              Top/Roof. Верхня частина настільки пошкоджена, що геометрія кузова
              не підлягає відновленню.
            </li>
            <li>
              Front End. Капот повністю розбитий через лобове зіткнення з іншим
              авто, будівлею чи огорожею.
            </li>
            <li>
              Water/Flood. Машина тривалий час перебувала у воді: прісній або
              солоній.
            </li>
            <li>Burn. Загоряння салону чи відсіку двигуна.</li>
            <li>
              Biohazard/Chemical. Сильний запах усередині машини, викликаний
              трупною отрутою людини/тварини або пальним.
            </li>
            <li>
              Partial/Incomplete Repair. Страхова компанія не може закінчити
              ремонт практично повністю розбитого авто, тож виставляє його на
              аукціон. Інший продавець купує ТЗ і для продажу робить із нього
              «ляльку». У таких випадках вам потрібно знати, як перевірити авто
              перед покупкою.
            </li>
            <li>
              Rollover. Під час аварії автомобіль перекинувся, внаслідок чого
              порушилася геометрія кузова.
            </li>
            <li>
              Undercarriage. Серйозні ушкодження днища/підвіски, через які
              спостерігаються несправності у трансмісії чи ДВЗ.
            </li>
            <li>
              Missing/Altered VIN. Знищено або спиляно ВІН-код через
              «кримінальне» минуле машини.
            </li>
            <li>
              VP-Replaced VIN. Замінено ідентифікаційний номер, а отже,
              транспортний засіб зібрано з кількох авто.
            </li>
          </ol>
        </p>
        <h3>ЧОМУ ВАРТО КУПУВАТИ БИТІ АВТО ЗІ США?</h3>
        <p className="articletext">
          Головною перевагою такого придбання є унікальне співвідношення ціни та
          якості. Навіть якщо приплюсувати до оцінної вартості аукціонного лота
          розмитнення та доставлення, ви все одно отримаєте ціну нижчу за
          ринкову. Ваша економія становитиме в межах 25-50%. Ось ще кілька
          аргументів на користь того, чи варто купувати машину, яку пригнали зі
          США:
          <ol>
            <li>
              Реальний пробіг. За «скручування» кілометражу в Америці
              передбачено кримінальну відповідальність.
            </li>
            <li>
              Один власник. Американці не люблять купувати вживані авто, тому
              вони у них завжди нові.
            </li>
            <li>
              Найкраща комплектація. У Штатах транспортні засоби укомплектовані
              за класом «All inclusive».
            </li>
            <li>
              Висока якість збирання, палива та доріг. Від цих трьох факторів
              залежить технічний стан машини.
            </li>
            <li>
              Вік. Виставленим на аукціон автомобілям не більше ніж 3-5 років.
            </li>
          </ol>
        </p>
        <p className="articletext">
          Пам’ятайте кілька нюансів. Нерідко європейські запчастини не підходять
          до американських машин. У зв’язку з цим ремонт битого авто доведеться
          замовляти у спеціалізованих автомайстернях. Оскільки ми співпрацюємо з
          кількома з них, то допоможемо з ними зв’язатися. До того ж ці СТО
          надають нам послуги за зниженими тарифами.
        </p>
        <p className="articletext">
          Придбання битого автомобіля з Америки — справа не для новачків.
          Самостійно знайти, доставити та розмитнити таку автівку буде занадто
          складно, не говорячи про заощадження на ній. До того ж завжди є ризик
          придбати автомотлох. Тому ми пропонуємо уникнути невдалої покупки,
          замовивши авто зі США у нас. Співпрацюючи з нами, ви вкладаєте гроші
          без ризиків та втрат.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Article3;
